export enum CookiesToken {
  emailConnection = 'effy-user-id',
  sessionToken = 'effy-connection-sessiontoken',
  refreshToken = 'effy-connection-refreshtoken',
  uid = 'effy-user-uid',
}

export enum CookiesGa {
  clientId = 'ga-client-id',
  utm = 'utm',
}

export const SIX_MONTH_IN_MILLISECONDS = 15552000000;
