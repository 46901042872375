import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CookiesToken, SIX_MONTH_IN_MILLISECONDS } from '../../configs/cookies.config';
import { AESEncryptDecryptService } from '../AESEncryptDecrypt/AESEncryptDecrypt.service';
import { JwtDecoderService } from '../jwt-decoder/jwt-decoder.service';

@Injectable({
  providedIn: 'root',
})
export class CookieManagerService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly aesService: AESEncryptDecryptService,
    private readonly jwtDecoder: JwtDecoderService
  ) {}

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  setCookie(name: string, token: string): void {
    const date = new Date();
    const { uid, exp } = this.jwtDecoder.decode(token);

    if (name === CookiesToken.refreshToken || (name === CookiesToken.sessionToken && uid)) {
      if (exp) {
        date.setTime(exp * 1000);
      } else {
        date.setTime(date.getTime() + SIX_MONTH_IN_MILLISECONDS);
      }
      this.cookieService.set(name, token, { expires: date, path: '/' });
      if (name === CookiesToken.sessionToken && uid !== undefined) {
        this.cookieService.set(CookiesToken.uid, uid, { expires: date, path: '/' });
      }
    }
  }

  setEmailCookie(email: string, sessionToken: string): void {
    const { uid, exp } = this.jwtDecoder.decode(sessionToken);
    if (email && uid && exp) {
      const date = new Date();
      date.setTime(exp * 1000);
      this.cookieService.set(CookiesToken.emailConnection, this.aesService.encrypt(email), { expires: date, path: '/' });
    }
  }

  deleteCookieList(cookiesName: CookiesToken[]): void {
    cookiesName.forEach(cookieToDelete => {
      this.cookieService.delete(cookieToDelete, '/');
    });
  }
}
