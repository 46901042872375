import { Injectable } from '@angular/core';
import CryptoES from 'crypto-es';

@Injectable({
  providedIn: 'root',
})
export class AESEncryptDecryptService {
  private readonly secretKey = 'TobleroneIsBetterThanKinder';

  encrypt(value: string): string {
    return CryptoES.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    try {
      return CryptoES.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoES.enc.Utf8);
    } catch (error: unknown) {
      return '';
    }
  }
}
