import { Injectable } from '@angular/core';
import { JwtClaims, jwt_decode } from 'jwt-decode-es';

export interface jwtToken extends JwtClaims {
  uid?: string;
}

@Injectable({
  providedIn: 'root',
})
export class JwtDecoderService {
  /**
   * Decode the token
   * @param token - token à décoder
   * @returns Si décodage impossible renvoie un objet vide
   */
  decode(token: string): jwtToken {
    if (token && typeof token === 'string' && token.length > 0) {
      try {
        return jwt_decode(token);
      } catch {
        return {};
      }
    }
    return {};
  }
}
